import { filter, includes, uniq } from "lodash";
import React from "react";
import readXlsxFile from "read-excel-file";
import { CheckUnexistWards } from "api/ward/check-ward-exist";
import { parsePhoneNumber } from 'libphonenumber-js/max';


const HandleFileUpload = (e: any, SetErrorArray: React.Dispatch<React.SetStateAction<string[]>>, SetFarmersListValues: React.Dispatch<React.SetStateAction<any>>, SetIsCheckingFile: React.Dispatch<React.SetStateAction<boolean>>) => {
  SetErrorArray([]);
  SetFarmersListValues([]);
  if (e.currentTarget.files && e.currentTarget.files?.length > 0) {
    const fileName = e.currentTarget.files[0].name;
    const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (extension == "xlsx" || extension == "xls") {
      readXlsxFile(e.currentTarget.files[0])
        .then((rows): any => {
          let FileIndexer = rows.find((item) => item[0] == "farmer_name") ? 2 : 1;
          const FarmersDataArray = rows.filter((item) => item[0] != "first_name");

          const currentYear = new Date().getUTCFullYear();
          const invalidBirthYear = FarmersDataArray
            .map((item, index) => {
              const dateStr = item[4]?.toString();
              if (dateStr) {
                const date = new Date(dateStr);
                const birthYear = date.getUTCFullYear();
                if (isNaN(birthYear) || birthYear < 1900 || birthYear > currentYear) {
                  return index + 1; // Return the index (adjusted by 2 for row number) of invalid item
                }
              } else {
                return index + 1; // If dateStr is empty, it's invalid
              }
              return null; // Return null for valid items
            })
            .filter(item => item !== null); // Filter out null values


          if (invalidBirthYear.length === 0) {
            const invalidateGendersArrayIndexs = FarmersDataArray
              .map((item, index) => ((item[3]?.toString().toLowerCase() !== "male" || item[3]?.toString().toLowerCase() !== "female") && !item[3] ? index + 1 : null))
              .filter((item) => item);
            if (invalidateGendersArrayIndexs.length === 0) {
              if (FarmersDataArray.length > 0) {
                const UnAcceptedPhoneValidtionIndexArray: number[] = [];
                const UnAccpetedFarmerNameValidationIndexArray: number[] = [];

                FarmersDataArray.forEach((item, index) => {
                  try {
                    const currentItem = item[2];
                    const phoneNumber = parsePhoneNumber(currentItem?.toString()[0] === '+' ? currentItem.toString() : '+' + currentItem.toString());
                    const isValidPhoneNumber = phoneNumber.isPossible()
                    if (!isValidPhoneNumber) {
                      UnAcceptedPhoneValidtionIndexArray.push(index + FileIndexer);
                    }
                  } catch (error) {
                    UnAcceptedPhoneValidtionIndexArray.push(index + FileIndexer);
                  }
                  if (!item[0] || !item[1]) {
                    UnAccpetedFarmerNameValidationIndexArray.push(index + FileIndexer);
                  }
                });
                if (UnAcceptedPhoneValidtionIndexArray.length === 0) {
                  if (UnAccpetedFarmerNameValidationIndexArray.length === 0) {
                    const PhoneNumbersArray = FarmersDataArray.map((item, index) => item[2]);
                    if (uniq(PhoneNumbersArray).length === PhoneNumbersArray.length) {
                      SetIsCheckingFile(true);
                      CheckUnexistWards(
                        {
                          ward_id_list: uniq(FarmersDataArray.map((item) => item[3]).filter((item) => item != null)) as string[],
                        },
                        (error, resp) => {
                          if (error) {
                            console.log(error.message);
                          } else if (resp) {
                            SetFarmersListValues(
                              FarmersDataArray.map((item) => ({
                                firstName: item[0]?.toString(),
                                lastName: item[1]?.toString(),
                                phone: item[2]?.toString(),
                                gender: item[3]?.toString().toLowerCase(),
                                birthYear: item[4]?.toString(),
                              }))
                            );
                          }
                        }
                      );
                    } else {
                      const duplicatePhoneNumbers = filter(PhoneNumbersArray, (val, i, iteratee) => includes(iteratee, val, i + 1));

                      SetErrorArray(duplicatePhoneNumbers.map((item) => `duplicated phone number ${item}`));
                    }
                  } else {
                    SetErrorArray(UnAccpetedFarmerNameValidationIndexArray.map((item) => `Invalid Farmer Name In Line ${item}`));
                  }
                } else {
                  console.log(UnAcceptedPhoneValidtionIndexArray, 'UnAcceptedPhoneValidtionIndexArray')
                  SetErrorArray(UnAcceptedPhoneValidtionIndexArray.map((item) => `Invalid Phone Number In Line ${item}`));
                }
              } else {
                SetErrorArray(["Empty File"]);
              }
            } else {
              SetErrorArray([`Invalid Gender In Line ${invalidateGendersArrayIndexs.join(",")}`]);
            }
          } else {
            SetErrorArray(invalidBirthYear.map((item) => `Invalid birth year value In Line  ${item}`));
          }
        })
        .catch(err => {
          SetErrorArray(["UnExpected Error While Reading the File"]);
        })
    } else {
      SetErrorArray(["Unacceptable file format"]);
    }
  } else {
    SetErrorArray(["UnExpected Error"]);
  }
};

export default HandleFileUpload;